import { useState, useRef, useCallback, useEffect } from 'react';
import Toolbar from '../components/Toolbar';
import PreviewEditor from '../components/PreviewEditor';
import DownloadButton from '../components/DownloadButton';
import {gapi} from 'gapi-script';
import axios from 'axios';
import {Button,Modal,message} from 'antd'
import Popup from '../components/Popup';

const MIN_WIDTH = 300; 
const MIN_HEIGHT = 200; 
const client_id =process.env.REACT_APP_CLIENT_ID
const apiKey = process.env.REACT_APP_API_KEY
const beUrl = process.env.REACT_APP_BE
const scope = 'https://www.googleapis.com/auth/drive.file'

const HomeScreen = () => {
  const [modal2Open, setModal2Open] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [separatorPos, setSeparatorPos] = useState(isMobile ? window.innerHeight / 2 : window.innerWidth / 2);
    const [editorSize, setEditorSize] = useState(isMobile ? window.innerHeight / 2 : window.innerWidth / 2);
    const [markdown, setMarkdown] = useState("");
    const containerRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileId,setFileId] = useState('')
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const dropdownRef = useRef(null);
    const [fileName, setFileName] = useState('');

    const onChangeFileName = (event) => {
      setFileName(event.target.value);
    };

    useEffect(() => {
        const handleResize = () => {
          const mobile = window.innerWidth <= 768;
          setIsMobile(mobile);
          setSeparatorPos(mobile ? window.innerHeight / 2 : window.innerWidth / 2);
          setEditorSize(mobile ? window.innerHeight / 2 : window.innerWidth / 2);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        function start() {
          gapi.client.init({
            apiKey: apiKey,
            clientId: client_id,
            scope: scope
          }).then(() => {
            const authInstance = gapi.auth2.getAuthInstance();
            if (authInstance){
              setIsAuthenticated(authInstance.isSignedIn.get());
              authInstance.isSignedIn.listen(setIsAuthenticated); 
            }
          });
        }
    
        gapi.load('client:auth2', start);
    }, []);
    useEffect(() => {
      if (dropdownOpen) {
          document.addEventListener("mousedown", handleClickOutside);
      } else {
          document.removeEventListener("mousedown", handleClickOutside);
      }

      // Cleanup the event listener when component unmounts
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [dropdownOpen]);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropdownOpen(false);
      }
  };
    const handleUploadToDrive = async (fileName) => {
        const uploadFile = async () => {
          try {
            const accessToken = gapi.auth.getToken().access_token;
            if (!accessToken) return;
            
            const response = await axios.post(
              `${beUrl}/api/filetransform/mdtodocx`,
              { markdown },
              { responseType: 'blob' }
            );
            
            const fileBlob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            const finalFileName = fileName ? fileName : `Document_${currentDate}`;
            const metadata = {
              name: finalFileName,
              mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            };
      
            const formData = new FormData();
            formData.append(
              'metadata',
              new Blob([JSON.stringify(metadata)], { type: 'application/json' })
            );
            formData.append('file', fileBlob);
            
            const uploadResponse = await axios.post(
              'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
              formData,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            
            const uploadedFileId = uploadResponse.data.id;
            setFileId(uploadedFileId);
            
            window.open(`https://docs.google.com/document/d/${uploadedFileId}/edit`);
          } catch (error) {
            console.error('Error uploading file to Google Drive:', error);
            alert('Failed to upload the file to Google Drive');
          }
        };
      
        if (!isAuthenticated) {
          
          const authInstance = gapi.auth2.getAuthInstance();
          authInstance.signIn().then(() => {
            setIsAuthenticated(true);  
            setPopupTitle('Authentication Successful');
            setPopupMessage("Click on 'Export to Google Docs' Button Again");
            setIsPopupOpen(true);
          }).catch((error) => {
            console.error("Google login failed:", error);
            setPopupTitle('Upload Failed');
            setPopupMessage('An error occurred while uploading to Google Drive.');
            setIsPopupOpen(true);
          });
        } else {
          uploadFile(); 
        }
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const handleDownload = async (fileName) => {
        setLoading(true);
        try {
          const response = await axios.post(
            `${beUrl}/api/filetransform/mdtodocx`,
            { markdown },
            { responseType: 'blob' }
          );
          const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
          const finalFileName = fileName ? fileName : `Document_${currentDate}`;

          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${finalFileName}.docx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading file:', error);
          alert('Failed to download the file');
        } finally {
          setLoading(false);
        }
    };

    const onMouseMove = useCallback((event) => {
    if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
    
        if (isMobile) {
        
        let newSeparatorPos = event.clientY - containerRect.top;
        newSeparatorPos = Math.max(MIN_HEIGHT, Math.min(containerRect.height - MIN_HEIGHT, newSeparatorPos));
        setSeparatorPos(newSeparatorPos);
        setEditorSize(newSeparatorPos); 
        } else {
        let newSeparatorPos = event.clientX - containerRect.left;
        newSeparatorPos = Math.max(MIN_WIDTH, Math.min(containerRect.width - MIN_WIDTH, newSeparatorPos));
        setSeparatorPos(newSeparatorPos);
        setEditorSize(newSeparatorPos); 
        }
    }
    }, [isMobile]);

    const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    }, [onMouseMove]);

    const onMouseDown = useCallback((event) => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    }, [onMouseMove, onMouseUp]);

    const onTouchMove = useCallback((event) => {
      if (containerRef.current) {
          const containerRect = containerRef.current.getBoundingClientRect();
  
          // Use the touch position (event.touches[0]) for mobile devices
          if (isMobile) {
              let newSeparatorPos = event.touches[0].clientY - containerRect.top;
              newSeparatorPos = Math.max(MIN_HEIGHT, Math.min(containerRect.height - MIN_HEIGHT, newSeparatorPos));
              setSeparatorPos(newSeparatorPos);
              setEditorSize(newSeparatorPos); 
          } else {
              let newSeparatorPos = event.touches[0].clientX - containerRect.left;
              newSeparatorPos = Math.max(MIN_WIDTH, Math.min(containerRect.width - MIN_WIDTH, newSeparatorPos));
              setSeparatorPos(newSeparatorPos);
              setEditorSize(newSeparatorPos); 
          }
      }
  }, [isMobile]);
  
  const onTouchEnd = useCallback(() => {
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
  }, [onTouchMove]);
  
  const onTouchStart = useCallback((event) => {
      window.addEventListener('touchmove', onTouchMove);
      window.addEventListener('touchend', onTouchEnd);
  }, [onTouchMove, onTouchEnd]);


    const formatMarkdown = (content, command) => {
        switch (command) {
          case 'heading1':
            return content ? `# ${content}` : '# Title 1\n';
          case 'heading2':
            return content ? `## ${content}` : '## Title 2\n';
          case 'heading3':
            return content ? `### ${content}` : '### Title 3\n';
          case 'bold':
            return content ? `**${content}**` : '**Sample Bold Text**';
          case 'underline':
            return content ? `<u>${content}</u>` : '<u>Sample Underline Text</u>';
          case 'italic':
            return content ? `_${content}_` : '_Sample Italic Text_';
          case 'blockCode':
            return content ? `\n\`\`\`js\n${content}\n\`\`\`\n` : '\n```js\n// code block\n```';
          case 'link':
            return content ? `[${content}](http://example.com)` : '[Link](http://example.com)';
          case 'image':
            return content ? `![${content}](http://example.com/image.jpg)` : '![Alt text](http://example.com/image.jpg)';
          case 'quote':
            return content ? `> ${content}` : '> Quote';
          case 'list':
            return content ? `- ${content}` : '- List item';
          case 'orderedList':
            return content ? `1. ${content}` : '1. Ordered item';
          case 'table':
            return content ? `| ${content} |` : '\n| Header | Header |\n| ------- | ------- |\n| Cell 1  | Cell 2  |';
          case 'strikethrough':
            return content ? `~~${content}~~` : '~~strikethrough~~';
          case 'taskList':
            return content ? `- [ ] ${content}` : '\n- [ ] Task 1\n- [x] Task 2';
          case 'html':
            return content ? `<blockquote>${content}</blockquote>` : '\n<blockquote>HTML content</blockquote>';
          default:
            return content || ''; 
        }
    };

    const handleFormat = (command) => {
        const textarea = document.querySelector('.markdown-editor');
        const { selectionStart, selectionEnd, value } = textarea;
      
        
        if (selectionStart !== selectionEnd) {
          const selectedText = value.substring(selectionStart, selectionEnd);
          const formattedText = formatMarkdown(selectedText, command);
      
          
          setMarkdown(
            value.substring(0, selectionStart) +
            formattedText +
            value.substring(selectionEnd)
          );
      
          
          setTimeout(() => {
            textarea.setSelectionRange(selectionStart, selectionStart + formattedText.length);
          }, 0);
        } else {
          
          const placeholder = formatMarkdown('', command);
      
          setMarkdown(
            value.substring(0, selectionStart) +
            placeholder +
            value.substring(selectionStart)
          );
      
          
          setTimeout(() => {
            textarea.setSelectionRange(selectionStart + placeholder.length, selectionStart + placeholder.length);
          }, 0);
        }
    };  
    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };
    useEffect(() => {
      // Add touch event listeners for mobile
      return () => {
          window.removeEventListener('touchmove', onTouchMove);
          window.removeEventListener('touchend', onTouchEnd);
      };
  }, [onTouchMove, onTouchEnd]);


  
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [textarea, settextarea] = useState("");
  const handleGenerate = async () => {
    setConfirmLoading(true);
    
    try {
      const response = await axios.post(`${beUrl}/api/llm/generateMD`, {
        query: textarea,
      });

      
      setMarkdown(response.data.response);
      setModal2Open(false); // Close modal once response is received
    } catch (error) {
      alert(error.message);
      console.log(error);
    } finally {
      settextarea("")
      setConfirmLoading(false); // Stop loading indicator
    }
  };

  const [Loader, setLoader] = useState(false);
    
  const handleFormatMarkDown = async() =>{
   setLoader(true);   
    // Dismiss manually and asynchronously      
    console.log(markdown)
    try {
      const response = await axios.post(`${beUrl}/api/llm/reformatMD`,{
        "markdown" : markdown
      })
      console.log(response)
      if(response.data.status === true){
        setMarkdown(response.data.response);
        message.success("Formatted Successfully")
        setLoader(false)
      }
      else{
        message.error("Some error occured in formating")
        setLoader(false)
      }
    } catch (error) {
      console.log(error)
      setLoader(false)
      message.error(error.response.data.message)
    }
  }
    return (
    <>
    {Loader && <div
  style={{
    position: "fixed", // Ensure the loader covers the whole screen
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: "blur(3px)", // Adds blur effect to the background
    backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent dark overlay
    zIndex: 9999, // Ensures it's above other elements
  }}
>
  <div
    style={{
      width: "50px",
      height: "50px",
      border: "10px solid rgba(255, 255, 255, 0.3)", // Light outer border
      borderTop: "10px solid #305ee8", // Blue border for animation
      borderRadius: "50%",
      animation: "spin 1s linear infinite", // Loader animation
    }}
  ></div>
  <style>
    {`
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
  </style>
</div>
}

 <div className="App">
      <div className='logo-heading my-3'>
        <div className='logo'>
        <a href='https://www.consultanubhav.com/' target='_blank'>
          <img  className="company-logo" src='CA logo dark.svg' width="35%" alt='Logo' />
        </a>
        <p className='app-title'>GPT2DOCX</p>       
       
        </div>       
        
        <div className='action-buttons'>    
        <Button type="secondary" onClick={handleFormatMarkDown}>
        Format with AI
      </Button>    
        <Button type="primary" onClick={() => setModal2Open(true)}>
        Generate with AI
      </Button>
        
      <Modal
        title="Generate your content with AI"
        centered
        open={modal2Open}
        okText={"Generate"}
        onOk={handleGenerate}
        confirmLoading={confirmLoading} 
        onCancel={() => setModal2Open(false)}
      >
        <textarea   value={textarea} onChange={(e)=>settextarea(e.target.value)} placeholder='Enter your Prompt here or Markdown Text' className='textarea'></textarea>
       
      </Modal>
          <div className="dropdown" ref={dropdownRef}>
            <img 
              src='export.svg' 
              alt="Menu" 
              className="hamburger-icon"
              onClick={handleDropdownToggle} 
            />
            {dropdownOpen && (
              <div className="dropdown-menu">
                <input
                  type="text"
                  value={fileName}
                  onChange={onChangeFileName}
                  placeholder="Enter file name (optional)"
                  disabled={loading}
                  style={{
                    width:"90%",
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                    flexGrow: 1,
                    fontSize: '14px',
                    marginRight: '10px'
                  }}
                />
                <DownloadButton handleDownload={()=>handleDownload(fileName)} loading={loading} />
                <button onClick={()=>handleUploadToDrive(fileName)}>
                    Export to Google Docs
                </button>
                <Popup
                    title={popupTitle}
                    message={popupMessage}
                    isOpen={isPopupOpen}
                    onClose={closePopup}
                />
              </div>
            )}
          </div>
          
          </div>
      </div>      
      <div className="editor-container" ref={containerRef}>
      <Toolbar onFormat={handleFormat} />
      <div className="editor-box" style={isMobile ? { height: `${editorSize}px` } : { width: `${editorSize}px` }}>
        <textarea
          className="markdown-editor"
          value={markdown}
          autoFocus
          onChange={(e) => setMarkdown(e.target.value)}
          placeholder="Paste your markdown here..."
        />
      </div>       
      <div
        className="separator"
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        style={isMobile ? { top: `${separatorPos}px` } : { left: `${separatorPos}px` }}
      >
        <div className="separator-icon">
          <svg width="16" height="50" viewBox="0 0 24 85" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="5.68457" width="24" height="76" rx="18" fill="#305EE8"/>
              <path d="M18 23.6846C18 26.9983 15.3137 29.6846 12 29.6846C8.68629 29.6846 6 26.9983 6 23.6846C6 20.3709 8.68629 17.6846 12 17.6846C15.3137 17.6846 18 20.3709 18 23.6846Z" fill="white"/>
              <path d="M18 41.6846C18 44.9983 15.3137 47.6846 12 47.6846C8.68629 47.6846 6 44.9983 6 41.6846C6 38.3709 8.68629 35.6846 12 35.6846C15.3137 35.6846 18 38.3709 18 41.6846Z" fill="white"/>
              <path d="M18 59.6846C18 62.9983 15.3137 65.6846 12 65.6846C8.68629 65.6846 6 62.9983 6 59.6846C6 56.3709 8.68629 53.6846 12 53.6846C15.3137 53.6846 18 56.3709 18 59.6846Z" fill="white"/>
          </svg>
          </div>
        
        </div>
        <div className="preview-box my-5" style={isMobile ? { height: `calc(100% - ${separatorPos}px)` } : { width: `calc(100% - ${separatorPos}px)` }}>
          <PreviewEditor className="preview-container" content={markdown} />
        </div>
      </div>
      
    </div>
    
    </>
    )
}

export default HomeScreen